@import url('https://fonts.googleapis.com/css2?family=Satoshi:wght@300;400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lobster&display=swap');

/* Global Styles */
body {
  background-color: var(--color-tertiary);
  color: var(--color-text-light);
  font-family: var(--font-primary);
  font-family: 'Roboto Mono', monospace;
  /* Default margin for small screens */
}



.cta-button {
  padding: var(--spacing-sm) var(--spacing-sm);
  /* Increased padding for bigger size */
  background-color: var(--color-primary);
  color: #fff;
  border-radius: var(--border-radius-lg);
  font-size: var(--font-size-lg);
  /* Increased font size */
  font-weight: var(--font-weight-bold);
  /* Made the font bold */
  text-decoration: none;
  transition: background-color 0.3s ease, transform 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  /* Added box-shadow for standout effect */
}

.cta-button:hover {
  background-color: var(--color-primary-light);
  transform: scale(1.05);
}





/* Scrollbar Styles */
body::-webkit-scrollbar {
  width: 12px;
}

body::-webkit-scrollbar-track {
  background: var(--color-quaternary);
  border-radius: 0;
  /* Boxy corners */
}

body::-webkit-scrollbar-thumb {
  background-color: var(--color-primary);
  border-radius: 0;
  /* Boxy corners */
  border: 2px solid var(--color-quaternary);
}

body::-webkit-scrollbar-thumb:hover {
  background-color: var(--color-primary-light);
}

body::-webkit-scrollbar-thumb:active {
  background-color: var(--color-primary);
}

/* Container Style for Consistent Padding */
.container {
  padding: 0 20px;
  /* Adds horizontal padding to containers */
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.Newsletter-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Dark overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.Newsletter-popup-content {
  background: transparent;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  max-width: 900px;
  width: 80%;
  position: relative;
}

.popup-iframe {
  width: 100%;
  height: 70vh; /* Default height */
  border: none;
}

.Newsletter-close-btn {
  position: absolute;
  top: -15px;
  right: -15px;
  color: white;
  border: none;
  background-color: #000;
  font-size: 20px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
}

/* Media query for taller screens like iPads */
@media (min-height: 800px) {
  .popup-iframe {
      height: 75vh; /* Reduced height for tall screens */
  }
}

@media (min-height: 1200px) {
  .popup-iframe {
      height: 50vh; /* Reduced height for tall screens */
  }
}
