/* General Styling */
.all-blogs {
    padding: var(--spacing-xl) var(--spacing-lg);
    background-color: var(--color-background);
    color: var(--color-text-light);
    font-family: 'Roboto Mono', monospace;
    text-align: left;
}

.all-blogs-container {
    max-width: 1200px;
    margin: 0 auto;
}

.all-blogs-title {
    font-size: var(--font-size-title);
    font-weight: var(--font-weight-bold);
    color: var(--color-primary);
    margin-bottom: var(--spacing-lg);
    text-align: left;

}

.all-blogs-content {
    display: flex;
    flex-wrap: wrap;
    gap: var(--spacing-lg);
}

.all-blogs-card {
    background: var(--color-secondary);
    padding: var(--spacing-md);
    border-radius: var(--border-radius);
    box-shadow: var(--shadow-light);
    cursor: pointer;
    transition: transform 0.2s, box-shadow 0.2s;
    flex: 1 1 calc(25% - var(--spacing-lg));
    margin-bottom: var(--spacing-lg);
    text-decoration: none;
    color: inherit;
}

.all-blogs-card:hover {
    transform: scale(1.05);
    box-shadow: var(--shadow-medium);
}

.all-blogs-header h2 {
    font-size: var(--font-size-md);
    margin-bottom: var(--spacing-sm);
}

.all-blogs-description {
    font-size: var(--font-size-sm);
    color: var(--color-text-dark);
    margin-bottom: var(--spacing-sm);
}

.all-blogs-date {
    font-size: var(--font-size-sm);
    color: var(--color-text-muted);
}

.more-container {
    text-align: center;
    margin-top: var(--spacing-lg);
}

.more-link {
    font-size: var(--font-size-md);
    color: var(--color-primary);
    text-decoration: none;
    transition: color 0.2s;
}

/* .more-link:hover {
    color: var(--color-secondary);
} */

@media (max-width: 768px) {
    .all-blogs-content {
        flex-direction: column;
    }

    .all-blogs-card {
        flex: 1 1 100%;
    }
}