/* Import Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;700&display=swap');

/* Color Palette */
:root {
    --color-background: #fff;
    /* White background */
    --color-primary: #000;
    /* Black as primary color */
    --color-primary-light: #333;
    /* Dark grey as lighter primary */
    --color-secondary: #f5f5f5;
    /* Light grey as secondary color */
    --color-tertiary: #e0e0e0;
    /* Lighter grey as tertiary color */
    --color-quaternary: #ccc;
    /* Even lighter grey as quaternary color */
    --color-text-light: #000;
    /* Black text on light backgrounds */
    --color-text-muted: #666;
    /* Muted text color */
    --color-footer: #000;
    /* Black footer */
}

/* Fonts */
:root {
    --font-primary: 'Poppins', sans-serif;
    /* Clean, elegant body font */
    --font-secondary: 'Playfair Display', serif;
    /* Elegant, fancy heading font */
}

/* Font Sizes */
:root {
    --font-size-xs: 0.75rem;
    --font-size-sm: 0.875rem;
    --font-size-md: 1rem;
    --font-size-lg: 1.25rem;
    --font-size-xl: 1.5rem;
    --font-size-xxl: 2rem;
    --font-size-title: 3rem;
}

/* Font Weights */
:root {
    --font-weight-light: 300;
    --font-weight-regular: 400;
    --font-weight-bold: 700;
}

/* Shadows */
:root {
    --shadow-light: 0 4px 8px rgba(0, 0, 0, 0.1);
    --shadow-medium: 0 4px 8px rgba(0, 0, 0, 0.2);
    --shadow-heavy: 0 8px 16px rgba(0, 0, 0, 0.3);
}

/* Spacing */
:root {
    --spacing-xs: 4px;
    --spacing-sm: 8px;
    --spacing-md: 16px;
    --spacing-lg: 24px;
    --spacing-xl: 32px;
    --spacing-xxxl: 48px;
}

/* Border Radius */
:root {
    --border-radius-sm: 0;
    /* Sharp corners */
    --border-radius-md: 0;
    /* Sharp corners */
    --border-radius-lg: 0;
    /* Sharp corners */
}

/* Example Heading Style */
h1, h2, h3 {
    font-family: var(--font-secondary);
    font-weight: var(--font-weight-bold);
}

/* Example Body Text Style */
p, span, a, li {
    font-family: var(--font-primary);
    font-weight: var(--font-weight-regular);
}
