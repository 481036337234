@import '../style/styles.css';
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@400;700&display=swap');

.blog-post-container {
    padding: var(--spacing-xl) var(--spacing-lg);
    background-color: var(--color-tertiary);
    text-align: center;
    min-height: 100vh;
    position: relative;
}

.back-button {
    position: absolute;
    top: var(--spacing-md);
    left: var(--spacing-md);
    background-color: var(--color-secondary);
    color: var(--color-text-light);
    font-family: 'Roboto Mono', monospace;
    font-size: var(--font-size-sm);
    padding: var(--spacing-xs) var(--spacing-sm);
    border: none;
    border-radius: var(--border-radius-sm);
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.back-button:hover {
    background-color: var(--color-secondary-dark);
}

.blog-post-content {
    max-width: 1000px;
    margin: 0 auto;
    background-color: var(--color-background-light);
    padding: var(--spacing-lg);
    border-radius: var(--border-radius-md);
    box-shadow: var(--box-shadow-lg);
}

.blog-post-title {
    font-size: var(--font-size-title);
    font-weight: var(--font-weight-bold);
    color: var(--color-text-dark);
    margin-bottom: var(--spacing-md);
    text-transform: capitalize;
    text-align: left;
}

.blog-post-body {
    font-size: var(--font-size-md);
    color: var(--color-text-dark);
    line-height: 1.8;
    margin-bottom: var(--spacing-lg);
    text-align: left;
}

/* Ensure images are responsive */
.blog-post-body img {
    max-width: 100%;
    height: auto;
    display: block;
    margin: var(--spacing-lg) auto;
}

/* Ensure videos are responsive */
.blog-post-body iframe {
    max-width: 100%;
    height: auto;
    display: block;
    margin: var(--spacing-lg) auto;
}

.blog-post-body p {
    margin-bottom: var(--spacing-md);
}

.blog-post-date {
    font-size: var(--font-size-sm);
    color: var(--color-text-dark);
    text-align: right;
    margin-top: var(--spacing-lg);
}

/* Responsive adjustments */
@media (max-width: 1024px) {
    .blog-post-content {
        padding: var(--spacing-md);
    }

    .blog-post-title {
        font-size: var(--font-size-xl);
    }
}

@media (max-width: 768px) {
    .blog-post-body {
        font-size: var(--font-size-sm);
    }

    .blog-post-title {
        font-size: var(--font-size-lg);
    }
}

@media (max-width: 480px) {
    .blog-post-container {
        padding: var(--spacing-lg) var(--spacing-md);
    }

    .blog-post-title {
        font-size: var(--font-size-md);
    }

    .blog-post-body {
        font-size: var(--font-size-sm);
        text-align: justify;
    }

    .blog-post-content {
        padding: var(--spacing-md);
    }

    .blog-post-date {
        font-size: var(--font-size-xs);
        text-align: center;
    }

    .back-button {
        font-size: var(--font-size-xs);
        padding: var(--spacing-xs);
    }
}