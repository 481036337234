/* Base styling for the OTP page */
.otp-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: var(--color-background);
    font-family: 'Roboto Mono', monospace;
}

/* Centered container for the OTP form */
.otp-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 400px;
    padding: var(--spacing-xl);
    background-color: var(--color-secondary);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

    gap: var(--spacing-md);
    text-align: center;
    box-sizing: border-box;
}

/* Styling for headings and messages */
h2 {
    font-size: var(--font-size-xl);
    margin-bottom: var(--spacing-lg);
}

p {
    font-size: var(--font-size-md);
    color: var(--color-text-muted);
}

/* Larger and more styled input field */
.input-otp {
    width: 100%;
    padding: var(--spacing-md);
    font-size: var(--font-size-lg);
    background-color: var(--color-quaternary);
    border: 1px solid var(--color-quinary);

    text-align: center;
    margin-bottom: var(--spacing-lg);
    box-sizing: border-box;
}

/* Button styling for CTA actions */
.cta-button-otp {
    width: 100%;
    padding: var(--spacing-md);
    background-color: var(--color-primary);
    color: #fff;
    font-size: var(--font-size-lg);

    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
    border: none;
}

.cta-button-otp:hover:not(:disabled) {
    background-color: var(--color-primary-light);
    transform: translateY(-2px);
}

.cta-button-otp:disabled {
    background-color: var(--color-quaternary);
    cursor: not-allowed;
}

/* Back button styling */
.back-button {
    margin-top: var(--spacing-md);
    background-color: var(--color-tertiary);
    padding: var(--spacing-md);
    font-size: var(--font-size-md);
    color: var(--color-text-light);
    border: none;

    cursor: pointer;
    transition: background-color 0.3s;
}

.back-button:hover {
    background-color: var(--color-primary-light);
}

/* Modal overlay and content */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-content {
    background-color: var(--color-secondary);
    padding: var(--spacing-lg);

    text-align: center;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.close-modal-btn {
    margin-top: var(--spacing-md);
    background-color: var(--color-primary);
    color: #fff;
    padding: var(--spacing-md);
    font-size: var(--font-size-md);

    cursor: pointer;
    border: none;
}