/* Nutrition Section */
.nutrition {
    padding: var(--spacing-xxl) var(--spacing-lg);
    background-color: var(--color-tertiary);
    color: var(--color-text-light);

    text-align: left;
    margin: 20px;
}

.container {
    max-width: 1200px;
    margin: 0 auto;
}

.nutrition-title {

    font-size: var(--font-size-title);
    font-weight: var(--font-weight-bold);
    color: var(--color-primary);
    margin-bottom: var(--spacing-lg);
    text-align: center;
}

.nutrition-title-content {

    font-size: var(--font-size-title);
    font-weight: var(--font-weight-bold);
    color: var(--color-primary);
    margin-bottom: var(--spacing-lg);
    text-align: left;
}

.nutrition-content {
    display: flex;
    flex-wrap: wrap;
    gap: var(--spacing-lg);
    margin-bottom: var(--spacing-lg);
}

.nutrition-card {
    background: var(--color-secondary);
    padding: var(--spacing-md);
    border-radius: var(--border-radius);
    box-shadow: var(--shadow-light);
    cursor: pointer;
    transition: transform 0.2s;
    flex: 1 1 calc(25% - var(--spacing-lg));
    margin-bottom: var(--spacing-lg);
}

.nutrition-card:hover {
    transform: scale(1.05);
    box-shadow: var(--shadow-medium);
}

.nutrition-card h2 {
    font-size: var(--font-size-md);
    margin-bottom: var(--spacing-sm);
}

.nutrition-card p {
    font-size: var(--font-size-sm);
    color: var(--color-text-dark);
}

.nutrition-link {
    text-decoration: none;
    color: inherit;
}

.blog-post .back-button {
    display: inline-block;
    margin-bottom: var(--spacing-lg);
    background-color: var(--color-primary);
    color: var(--color-background);
    padding: var(--spacing-sm) var(--spacing-md);
    border-radius: var(--border-radius);
    text-decoration: none;
    transition: background-color 0.2s;
}

.blog-post .back-button:hover {
    background-color: var(--color-secondary);
}

.blog-title {
    font-size: var(--font-size-xl);
    margin-bottom: var(--spacing-lg);
}

.blog-content {
    font-size: var(--font-size-md);
    margin-bottom: var(--spacing-lg);
}

.blog-date {
    font-size: var(--font-size-sm);
    color: var(--color-text-dark);
}

.more-container {
    text-align: center;
    margin-top: var(--spacing-lg);
}

/* .more-link {
    font-size: var(--font-size-md);
    color: var(--color-primary);
    text-decoration: none;
    transition: color 0.2s;
} */

/* .more-link:hover {
    color: var(--color-secondary);
} */

@media (max-width: 768px) {
    .nutrition-content {
        flex-direction: column;
    }

    .nutrition-card {
        flex: 1 1 100%;
    }
}