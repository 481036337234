/* Register Page */
.register-page {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    padding: var(--spacing-lg);
    background-color: var(--color-background);
}

.register-container {
    background-color: var(--color-secondary);
    padding: var(--spacing-xl);
    border-radius: var(--border-radius-lg);
    box-shadow: var(--shadow-medium);
    width: 100%;
    max-width: 600px;
    /* Increased for a more spacious desktop layout */
}

.register-title {
    font-size: var(--font-size-xxl);
    text-align: center;
    margin-bottom: var(--spacing-lg);
    color: var(--color-primary);
    font-weight: var(--font-weight-bold);
}

.input-field,
.input-file {
    width: 100%;
    padding: var(--spacing-md);
    /* More padding for a refined look */
    margin-bottom: var(--spacing-md);
    font-size: var(--font-size-md);
    border: 1px solid var(--color-tertiary);
    border-radius: var(--border-radius-sm);
    background-color: var(--color-background);
    color: var(--color-text-light);
    box-sizing: border-box;
}

/* Style image upload fields same as input fields */
.input-file {
    cursor: pointer;
}

.select-label,
.file-upload label {
    margin-bottom: var(--spacing-xs);
    font-size: var(--font-size-md);
    color: var(--color-text-muted);
    display: block;
}

/* .select-field,
.file-upload {
    margin-bottom: var(--spacing-md);
} */

.password-input-container {
    position: relative;
}

.password-toggle-icon {
    position: absolute;
    top: 50%;
    right: var(--spacing-md);
    /* Adjusted for better alignment */
    transform: translateY(-50%);
    cursor: pointer;
    color: var(--color-primary-light);
    font-size: 1.2rem;
    /* Increased for a clearer icon display */
}

.password-input-container .input-field {
    padding-right: 2.5rem;
    /* Spacing for the password toggle icon */
}



.modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
    background-color: var(--color-background);
    padding: var(--spacing-lg);
    border-radius: var(--border-radius-md);
    box-shadow: var(--shadow-medium);
    text-align: center;
    max-width: 400px;
    width: 100%;
}

.modal-close-btn {
    margin-top: var(--spacing-md);
    padding: var(--spacing-sm);
    background-color: var(--color-primary);
    color: #fff;
    border: none;
    cursor: pointer;
    border-radius: var(--border-radius-sm);
}

.modal-close-btn:hover {
    background-color: var(--color-primary-light);
}