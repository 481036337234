.team-container {
    padding: var(--spacing-xl);
    text-align: center;
}

.team-header {
    font-size: var(--font-size-title);
    margin-bottom: var(--spacing-lg);
}

.team-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    /* Center the items horizontally */
    gap: var(--spacing-lg);
    padding: var(--spacing-lg);
}

.team-member {
    background-color: var(--color-secondary);
    border-radius: var(--border-radius-md);
    box-shadow: var(--shadow-light);
    padding: var(--spacing-md);
    text-align: center;
    transition: box-shadow 0.3s ease-in-out;
    flex: 0 1 250px;
    /* Flex-grow: 0, Flex-shrink: 1, Flex-basis: 250px */
}

.team-member:hover {
    box-shadow: var(--shadow-medium);
}

.team-member img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    /* Ensures image covers the area without being distorted */
    margin-bottom: var(--spacing-md);
}

.team-member h3 {
    font-size: var(--font-size-sm);
    margin: 0;
}

.team-member p {
    font-size: var(--font-size-md);
    color: var(--color-text-muted);
}

/* Add media query for mobile screens */
@media (max-width: 768px) {
    .team-grid {
        overflow-x: auto;
        flex-wrap: nowrap;
        gap: var(--spacing-md);
        padding: var(--spacing-md);
        justify-content: flex-start;
        /* Align items to the start */
        scrollbar-width: none;
        /* Hide scrollbar in Firefox */
        -ms-overflow-style: none;
        /* Hide scrollbar in IE and Edge */
    }

    .team-grid::-webkit-scrollbar {
        display: none;
        /* Hide scrollbar in WebKit browsers (Chrome, Safari) */
    }

    .team-member {
        flex: 0 0 auto;
        min-width: 150px;
        /* Smaller width on mobile */
    }

    .team-member img {
        width: 150px;
        height: 150px;
        object-fit: cover;
        /* Ensures image covers the area without being distorted */
        margin: 0 auto var(--spacing-md);
        /* Center image and adjust margin */
    }
}