/* More Events Section */
.more-events {
    padding: var(--spacing-xl) var(--spacing-lg);
    background-color: var(--color-background);
    color: var(--color-text-light);
    font-family: 'Roboto Mono', monospace;
    text-align: center;
    /* Center the content */
    position: relative;
    /* Adjust padding for mobile */
}

.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 var(--spacing-lg);
    /* Add padding to ensure the content doesn't stick to the edges */
}

.more-events-title {
    font-size: var(--font-size-title);
    font-weight: var(--font-weight-bold);
    color: var(--color-primary);
    margin-bottom: var(--spacing-lg);
    text-align: center;
}

.more-events-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    /* Ensure a minimum width for cards */
    gap: var(--spacing-lg);
    justify-content: center;
    /* Center the grid */
    padding: var(--spacing-lg);
    /* Add padding around the grid */
}

.event-card {
    background: var(--color-secondary);
    padding: var(--spacing-md);
    border-radius: var(--border-radius);
    box-shadow: var(--shadow-light);
    cursor: pointer;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.event-card:hover {
    transform: scale(1.05);
    box-shadow: var(--shadow-medium);
}

.event-info h2 {
    font-size: var(--font-size-lg);
    margin-bottom: var(--spacing-sm);
}

.event-info p {
    font-size: var(--font-size-sm);
    color: var(--color-text-dark);
    margin-bottom: var(--spacing-sm);
}

.cta-button {
    display: inline-block;
    padding: var(--spacing-sm) var(--spacing-md);
    background-color: var(--color-primary);
    color: var(--color-background);
    text-decoration: none;
    border-radius: var(--border-radius);
    transition: background-color 0.2s ease;
}

.cta-button:hover {
    background-color: var(--color-secondary);
}

.back-button {
    position: absolute;
    top: var(--spacing-lg);
    left: var(--spacing-lg);
    background-color: var(--color-primary);
    color: var(--color-background);
    padding: var(--spacing-sm) var(--spacing-md);
    border-radius: var(--border-radius);
    text-decoration: none;
    transition: background-color 0.2s ease;
}

.back-button:hover {
    background-color: var(--color-secondary);
}

@media (max-width: 768px) {
    .more-events {
        padding: var(--spacing-md) var(--spacing-sm);
        /* Adjust padding for smaller screens */
    }

    .more-events-list {
        grid-template-columns: 1fr;
        gap: var(--spacing-md);
        /* Increase gap between cards on mobile */
        padding: var(--spacing-md);
        /* Ensure the grid has padding */
    }

    .event-card {
        margin-bottom: var(--spacing-md);
        /* Add margin to separate the cards */
    }

    .back-button {
        position: static;
        display: block;
        margin-bottom: var(--spacing-lg);
        /* Add margin below the back button */
    }
}