.how-it-works {
    padding: var(--spacing-xl);
    background-color: var(--color-secondary);
}

.how-it-works-title {
    text-align: center;
    font-size: var(--font-size-title);
    margin-bottom: var(--spacing-xxl);
}

.steps-and-image {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: var(--spacing-xl);
    text-align: left;
}

.steps {
    display: flex;
    flex-direction: column;
    width: 50%;
    justify-content: center;
}

.how-it-works-image {
    width: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.how-it-works-image img {
    width: 100%;
    height: auto;
    max-width: 100%;
    max-height: 600px;
    object-fit: cover;
    box-shadow: var(--shadow-light);
}

/* Adjust image quality for medium-sized screens (e.g., tablets) */
@media (min-width: 769px) and (max-width: 1024px) {
    .how-it-works-image {
        width: 350px; /* Reduce the width of the image to prevent it from getting too large */
    }

    .how-it-works-image img {
        max-height: 400px; /* Reduce height for better clarity on medium-sized screens */
        object-fit: contain; /* Contain the image to ensure no distortion */
    }
}

.how-it-works-content {
    width: 65%;
}

.step {
    display: flex;
    align-items: flex-start;
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.6s ease-in-out, transform 0.6s ease-in-out;
    margin: var(--spacing-lg) 0;
    text-align: left;
}

.step.in-view {
    opacity: 1;
    transform: translateY(0);
}

.number-circle {
    width: 40px;
    height: 40px;
    background-color: var(--color-tertiary);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: var(--font-weight-bold);
    color: var(--color-primary-light);
    margin-right: var(--spacing-md);
}

.step p {
    font-size: var(--font-size-md);
    line-height: 1.6;
    color: var(--color-primary);
}

/* Mobile styles */
@media (max-width: 768px) {
    .steps-and-image {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .steps {
        width: 100%;
    }

    .how-it-works-image {
        width: 100%;
    }

    .how-it-works-image img {
        max-height: 250px;
        object-fit: contain; /* Ensure the image fits better on mobile */
    }

    .step p {
        font-size: var(--font-size-sm);
    }

    .how-it-works-content {
        width: 100%;
    }
}
